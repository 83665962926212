<template>
  <div>
    <invoice-add></invoice-add>
  </div>
</template>

<script>
import InvoiceAdd from '../invoice-add/InvoiceAdd.vue';

export default {
  components: {
    InvoiceAdd,
  },

  data() {
    return {};
  },
};
</script>
